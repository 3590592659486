export class ConsumoQuery 
{
    plantaId!: number;
    haciendaId!: number[];
    desde!: string;
    hasta!: string;
    turnoId!: number[];
}

export class ConsumoResponse 
{
    consumoId!: number;
    aperturaId!: number;
    aperturaName!: string;
    haciendaId!: number;
    haciendaName!: string;
    fechaInicio!: string;
    fechaCierre!: string;
    volumen!:string;
}
export class ConsumoAgrupacion
{
    volumen!:number;
    espesor!:string;
}