











































































































































import {Vue,Component} from 'vue-property-decorator';
import moment from 'moment';
import {State,Action} from 'vuex-class';
import { Planta } from '@/entidades/Maestro/Planta';
import {ObtenerConsumoFecha,ObtenerConsumoDetalle,DescargarExcelConsumo,DescargarExcelConsumoDetalle} from '@/entidades/KTB/Reporte/ConexionDbReporte';
import {TurnoQuery,ObtenerTurnoPlanta} from '@/entidades/KTB/RegistroInformacion/Turno';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
import HaciendaItem from '@/entidades/Plantacion/HaciendaItem';
import {ConsumoQuery,ConsumoResponse,ConsumoAgrupacion} from '@/entidades/KTB/Reporte/ConsumoResponseReporte';
import {GenerapSapConsumo} from '@/entidades/Sap/GenerarSapGlobal';
@Component({
    name : 'RecepcionTally',
    components:{
        'tabla' : () => import('@/components/General/TablaGeneralFinal.vue')
    }
})
export default class ConsumoReport extends Vue
{
   @State('plantaAsignada',{namespace:'authModule'}) plantaAsignada!:Array<Planta>;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function
    menu:boolean =false;
    menu2:boolean =false;
    desde:string ="";
    hasta:string ="";
    listadoTurno:Array<TurnoQuery> = [];
    listadoHacienda:Array<HaciendaItem> = [];
    listadoAgrupacion:Array<ConsumoAgrupacion> = [];
    turnoId:Array<number> = [];
    haciendaId:Array<number> = [];
    numeroDespacho:string="";
    plantaId:number=0;
    componentName:string="";
    componentNameAgrupacion:string="";
    componenteOpen:number = 1;
    componentModel:boolean=false;
    //#region Propiedades de la tabla
    header:{text:string,sortable:boolean,value:string}[] =
    [
        {text:"Consumo",sortable:true,value:'consumoId'},
        {text:"Turno",sortable:true,value:'aperturaName'},
        {text:"Hacienda",sortable:true,value:'haciendaName'},
        {text:"Fecha Inicio",sortable:true,value:'fechaInicio'},
        {text:"Fecha Cierre",sortable:false,value:'fechaCierre'},
        {text:"Volumen",sortable:true,value:'volumen'},
        {text:"Action",sortable:true,value:'actions'}
    ];
    headerDetalle:{text:string,sortable:boolean,value:string}[] =
    [

        {text:"Largo",sortable:true,value:'largoName'},
        {text:"Espesor",sortable:true,value:'espesorName'},
        {text:"Ancho",sortable:false,value:'anchoName'},
        {text:"# Plantilla",sortable:true,value:'numeroPlantillas'},
        {text:"Pallet Visual",sortable:true,value:'palletIdVisual'},
        {text:"Fecha Tumba",sortable:true,value:'fechaTumba'},
        {text:"Volumen",sortable:true,value:'volumen'},
        {text:"Documento Sap",sortable:true,value:'documentoSap'},
        {text:"Mensaje Sap",sortable:true,value:'mensajeSap'},
        {text:"Action",sortable:true,value:'actions'}
    ];
    headerAgrupacion:{text:string,sortable:boolean,value:string}[] =
    [
        {text:"Espesor",sortable:true,value:'espesor'},
        {text:"Voumen",sortable:true,value:'volumen'}
    ];

    listado:Array<ConsumoResponse> =[];
    listadoDetalle:Array<any> =[];
    //#endregion
    //#region Eventos
    closeDesde(fecha:string)
    {
        this.menu = false;
        this.desde = fecha;

    }
    closeHasta(fecha:string)
    {
        this.hasta = fecha;
        this.menu2 = false;
    }
    async obtenerConsumo()
    {
        try
        {
            this.changeLoading(new Loading(true,"Obteniendo informacion...Espere por favor"));
            var filtro = new ConsumoQuery();
            filtro.plantaId = this.plantaId;
            filtro.haciendaId = this.haciendaId;
            filtro.desde = this.desde;
            filtro.hasta = this.hasta;
            filtro.turnoId = this.turnoId;
            var response = await ObtenerConsumoFecha(filtro);
            if(response.isSuccess)
            {
                this.listado = response.isResult;
            }
            else
            {
                this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
            }
        }
        catch(error)
        {
            this.changeAlerta(new Alerta(String(error),true,"danger",3000));
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }

    async openPallet(item:ConsumoResponse)
    {
        try
        {
            this.changeLoading(new Loading(true,"Obteniendo detalle"));
            var response = await ObtenerConsumoDetalle(item.consumoId);
            console.log(response);
            if(response.isSuccess)
            {
                //@ts-ignore
                this.listadoDetalle = response.isResult.items;
                //@ts-ignore
                this.listadoAgrupacion = response.isResult.agrupacion;
                this.componentName = 'tabla';
                this.componentModel = true;
            }
        }
        catch(error)
        {
            this.changeAlerta(new Alerta(String(error),true,"danger",3000));
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
    //#endregion
    async mounted()
    {
        this.plantaId = this.plantaAsignada[0].plantaId;
        this.desde = moment(new Date()).format("YYYY-MM-DD");
        this.hasta = moment(new Date()).format("YYYY-MM-DD");
        var response = await ObtenerTurnoPlanta(this.plantaId);
        if(response.isSuccess)
        {
            this.listadoTurno = response.isResult;
        }
        var rsp = await HaciendaItem.ObtenerHaciendaRecepction(this.plantaId,2);
        if(rsp.isSuccess)
        {
            this.listadoHacienda = rsp.isResult;
        }
    }
    async exportarExcel()
    {
        try
        {
            this.changeLoading(new Loading(true,"Exportando a excel"));
            var response = await DescargarExcelConsumo(this.listado);
            let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
            url = window.URL.createObjectURL(blob);
            window.open(url);
        }
        catch(error)
        {
            this.changeAlerta(new Alerta(String(error),true,"danger",3000));
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
    async exportarExcelDetalle(item:ConsumoResponse)
    {
        try
        {
            this.changeLoading(new Loading(true,"Exportando a excel"));
            var response = await DescargarExcelConsumoDetalle(item);
            let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
            url = window.URL.createObjectURL(blob);
            window.open(url);
        }
        catch(error)
        {
            this.changeAlerta(new Alerta(String(error),true,"danger",3000));
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
    async generarSapConsumo(item:any)
    {
      console.log('objeto recibidfo',item);
       this.changeLoading(new Loading(true,"Generando sap manual"));
      try
      {
        var listado:number[] = []
        listado.push(item.consumoDetalleId);
        var response = await GenerapSapConsumo(listado);
        console.log(response);
        if(response.isSuccess)
        {
           this.changeAlerta(new Alerta(response.isMessage,true,"primary",3000));
        }
        else
        {
          this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
        }
      }
      catch(error)
      {
        console.log(error);
      }
      finally
      {
         this.changeLoading(new Loading(false,""));
      }
    }
    //#region Computed
    get componentProperties()
    {
        if(this.componentName == 'tabla')
        {
            return {'headers' : this.headerDetalle, 'items' : this.listadoDetalle,'busqueda':false};
        }

    }
    //#endregion
}
